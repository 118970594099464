import React, { useEffect } from "react"
import EmbedSpotifyFollow from "./EmbedSpotifyFollow"
import EmbedSpotifyPlaylist from "./EmbedSpotifyPlaylist"
import { removeCookie, useConsent } from "../../../utils"

const Embed = ({ content, type }) => {
  const consent = useConsent()

  return (
    <>
      {(consent.analytics || consent.marketing) && (
        <>
          {type === "spotify_follow" && (
            <EmbedSpotifyFollow content={content} />
          )}
          {type === "spotify_playlist" && (
            <EmbedSpotifyPlaylist content={content} />
          )}
        </>
      )}
    </>
  )
}

export default Embed
