import React from "react"
import HeroColor from "./HeroColor"
import HeroImage from "./HeroImage"
import HeroVideo from "./HeroVideo"

const Hero = ({ content, type }) => {
  return (
    <>
      {type === "image" && <HeroImage content={content} />}
      {type === "color" && <HeroColor content={content} />}
      {type === "video" && <HeroVideo content={content} />}
    </>
  )
}

export default Hero
