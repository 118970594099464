import React from "react"
import styled from "styled-components"
import { media } from "../../constants/breakpoints"

const StyledWrapper = styled.div`
  align-self: center;
`
const StyledLabel = styled.label`
  margin-bottom: 0.5em;
  position: relative;
  display: block;
  font-size: 1em;
  font-weight:bold;
  @media ${media.large} {
    font-size: 0.8em;
  }
`
const StyledInput = styled.input`
  position: relative;
  display: block;
  box-shadow: 0px 5px 25px -4px rgba(0, 0, 0, 0.15);
  padding: 1.5em;
  width: 22em;
  border: none;
  border-radius: 0.5em;
  margin-bottom: 2em;
  font-size: 13.33px; //because chrome has 13.33 and safari uses 11
  &::placeholder {
    opacity: 0.5;
  }
  &[data-valid="false"] {
    border: 1px solid red;
  }
  &[data-valid="true"] {
    border: 1px solid green;
  }
`

const TextFieldAlterna = ({
  name,
  label,
  placeholder,
  type,
  pattern,
  required,
}) => {
  return (
    <StyledWrapper>
      <StyledLabel for={name}>{label}</StyledLabel>
      <StyledInput
        type={type}
        name={name}
        pattern={pattern}
        placeholder={placeholder}
        required={required}
      />
    </StyledWrapper>
  )
}

export default TextFieldAlterna
