import React from "react"
import { Body } from "../../typography"
import styled from "styled-components"
import { spacing } from "../../../constants/grid"
import { media } from "../../../constants/breakpoints"

const StyledWrapper = styled.div`
  text-align: center;
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  margin-bottom: ${spacing.medium};
  margin-top: ${spacing.medium};
  > *:not(:last-child) {
    margin-bottom: 0.5em;
  }

  @media ${media.medium} {
    grid-column-start: outer-x-left;
    grid-column-end: outer-x-right;
    margin-bottom: ${spacing.large};
    margin-top: ${spacing.large};
  }
  @media ${media.large} {
    grid-column-start: inner-left;
    grid-column-end: inner-right;
  }
`

const ParagraphIntro = ({ content }) => {
  const { primary } = content
  return (
    <StyledWrapper>
      <Body size={"big"} theme={content.theme} align="center">
        {primary.paragraph}
      </Body>
    </StyledWrapper>
  )
}

export default ParagraphIntro
