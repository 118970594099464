import React from "react"
import styled from "styled-components"
import { spacing } from "../../../constants/grid"

const StyledWrapper = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  margin-bottom: ${spacing.medium};
  margin-top: ${spacing.medium};
  display: grid;
  img {
    align-self: center;
    justify-self: center;
    max-width: 30em;
  }
`

const MediaImage = ({ content }) => {
  return (
    <StyledWrapper>
      <img src={content.primary.image.url} />
    </StyledWrapper>
  )
}

export default MediaImage
