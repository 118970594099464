import React from "react"
import styled from "styled-components"
import { Body } from "../../typography"
import { spacing } from "../../../constants/grid"
import Image from "../../../uiKit/Image"

const StyledWrapper = styled.div`
  grid-column-start: outer-xx-left;
  grid-column-end: outer-x-right;
  display: grid;
  grid-gap: 6em;
  grid-template-columns: repeat(auto-fit, minmax(6em, 1fr));
  margin-bottom: ${spacing.large};
  margin-top: ${spacing.small};
`
const StyledColumnWrapper = styled.div`
  display: grid;
  grid-gap: 1em;
  width: max-content;
  margin: auto;
`
const StyledLogo = styled.div`
  width: 6em;
  height: 6em;
`

const PartnersGrid = ({ content }) => {
  return (
    <StyledWrapper>
      {content.fields.map((field, i) => (
        <StyledColumnWrapper key={i}>
          <StyledLogo>
            <Image url={field.partner_logo.url} style={process.env.THEME} />
          </StyledLogo>

          <Body align="center">{field.partner_name}</Body>
        </StyledColumnWrapper>
      ))}
    </StyledWrapper>
  )
}

export default PartnersGrid
