import React, { useState } from "react"
import styled from "styled-components"
import Image from "../../../uiKit/Image"
import { Body } from "../../typography"
import { Person } from "../Person"
import { media } from "../../../constants/breakpoints"
import { colors, shades } from "../../../constants/colors"
import { spacing } from "../../../constants/grid"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useViewport } from "../../../utils"

const StyledCarouselWrapper = styled.div`
  grid-column-start: outer-xx-left;
  grid-column-end: outer-xx-right;
  color: ${colors.white};
  position: relative;
  margin-bottom: ${spacing.medium};
  margin-top: ${spacing.medium};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr max-content;

  @media ${media.large} {
    grid-column-start: outer-xxx-left;
    grid-column-end: outer-xxx-right;
    margin-bottom: ${spacing.large};
    margin-top: ${spacing.large};
    color: black;
  }
`
const StyledIndicators = styled.div`
  grid-area: 2/1/3/3;
  display: grid;
  grid-gap: 0.5em;
  grid-auto-flow: column;
  margin-top: 0.5em;
  @media ${media.large} {
    grid-area: 2/1/3/2;
    width: 25em;
    justify-self: right;
    margin-right: 2em;
  }
`
const StyledIndicator = styled.div`
  width: 100%;
  height: 0.2em;
  background-color: ${shades.gray60};
  position: relative;
  &:after {
    top: 0;
    left: 0;
    position: absolute;
    content: "";
    width: 0%;
    height: 100%;
    animation-duration: ${props => `${props.duration}s`};
    animation-name: ${props => (props.show ? "grow" : "none")};
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    background-color: ${props => colors[process.env.THEME][props.theme]};
  }

  @keyframes grow {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
`
const StyledSlidesWrapper = styled.div`
  grid-area: 1/1/2/3;
`
const StyledSlideWrapper = styled.div`
  display: ${props => (props.show ? "grid" : "none")};
  grid-template-rows: 1fr;
  @media ${media.large} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 4em;
    min-height: 25em;
  }
`

const StyledColumn = styled.div`
  grid-area: 1/1/2/2;
  position: relative;
  min-height: 30em;
  display: grid;
  align-items: end;
  @media ${media.large} {
    display: grid;
    justify-self: right;
    grid-area: auto;
    width: 25em;
    &:last-child {
      justify-self: left;
    }
  }
`
const StyledTextWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  padding: 4em;
  color: ${colors[process.env.THEME].white};
  @media ${media.large} {
    padding: 2em;
    color: ${colors[process.env.THEME].black};
    align-self: center;
  }
`

const TestimonialCarouselBig = ({ content }) => {
  const [slide, setSlide] = useState(0)
  const breakpoints = useBreakpoint()
  const viewport = useViewport()
  const slideDuration = 20

  const changeSlide = fromSlide => {
    let nextSlide = fromSlide + 1

    if (nextSlide > content.fields.length - 1) {
      nextSlide = 0
    }

    setSlide(nextSlide)
  }

  return (
    <>
      <StyledCarouselWrapper>
        <StyledSlidesWrapper>
          {content.fields.map((testimonial, index) => (
            <StyledSlideWrapper key={index} show={index === slide}>
              <StyledColumn>
                <Image
                  url={testimonial.image?.url}
                  darken={breakpoints.large ? false : true}
                  style={process.env.THEME}
                  glitch
                />
              </StyledColumn>
              <StyledColumn>
                <StyledTextWrapper viewport={viewport}>
                  <Body italic>"{testimonial.testimonial}"</Body>
                  {testimonial.author && (
                    <Person content={testimonial.author} mobileLeft />
                  )}
                </StyledTextWrapper>
              </StyledColumn>
            </StyledSlideWrapper>
          ))}
        </StyledSlidesWrapper>
        <StyledIndicators>
          {content.fields.map((testimonial, index) => (
            <StyledIndicator
              key={index}
              show={index === slide}
              slideId={index}
              duration={slideDuration}
              onAnimationEnd={() => changeSlide(index)}
              theme={content.theme}
            />
          ))}
        </StyledIndicators>
      </StyledCarouselWrapper>
    </>
  )
}

export default TestimonialCarouselBig
