import React, {useState} from "react"
import styled from "styled-components"
import { media } from "../../constants/breakpoints"
import Radio from "./Radio"
import Select from "../../uiKit/Select"

const StyledYears = styled.div`
  justify-self: center;
  @media ${media.large} {
    justify-self: left;
  }
`;

const StyledTravel = styled.div`
 display: flex;
 flex-direction:column;
  @media ${media.medium} {
    flex-direction:row;
  }
  @media ${media.large} {
    gap:2em;
  }
`;

const ClassPicker = ({ name, options, label }) => {
  const [is1011class, setIs1011class] = useState(false);

  const applicableYears = [];
  const thisYear = new Date().getFullYear() - 2000;
  for (let i = 0; i < 5; i++) {
    applicableYears.push(`${thisYear + i}/${thisYear + i + 1}`);
  }

  const handleChange = (val) => {
    if(val === '10/11 Klasse Prøvefri'){
      setIs1011class(true)
    }
    else{
      setIs1011class(false)
    }
  }

  return (
   <>
    <StyledYears>
        <Radio
          name="year"
          options={applicableYears}
          label="Vælg skoleår"
          required
        />
      </StyledYears>
      <StyledYears>
        <Radio
          name="class"
          options={['9. Klasse USA Fagligt', '10/11 Klasse Prøvefri']}
          label="Vælg klasse"
          required
          onChange={handleChange}
        />
      </StyledYears>
      <StyledTravel style={{display: is1011class ? "flex" : "none"}}>
        <Select name="travelFirstPriority[]" style="alterna" label="Rejser: 1. prioritet" options={["Afrika", "Mellemamerika", "Sydamerika"]}/>
        <Select name="travelSecondPriority[]" style="alterna" label="Rejser: 2. prioritet" options={["Afrika", "Mellemamerika", "Sydamerika"]}/>
      </StyledTravel>
   </>
  )
}

export default ClassPicker
