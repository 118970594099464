import React from "react"
import SelectAlterna from "./SelectAlterna"

const Select = props => {
  const { style } = props
  return (
    <>
      {style === "alterna" && (
        <SelectAlterna {...props}>{props.children}</SelectAlterna>
      )}
    </>
  )
}

export default Select
