import React from "react"
import styled from "styled-components"
import Heading from "../../typography/Heading"
import { media } from "../../../constants/breakpoints"
import Image from "../../media/image"
import { zLevels } from "../../../constants/grid"

const StyledWrapper = styled.div`
  width: 100%;
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  background-color: grey;
  min-height: 40em;
  height: 80vh;
  max-height: 70em;
  display: grid;
  align-items: center;
  justify-items: center;
  > * {
    grid-area: 1/1/2/2;
  }
  h1 {
    padding: 10%;
    font-size: 3.5em;
    position: relative;
    margin: auto;
  }

  @media only screen and (min-width: 375px) {
    h1 {
      font-size: 4.5em;
    }
  }

  @media ${media.medium} {
    height: 100%;
    h1 {
      padding: 10%;
    }
  }

  @media ${media.large} {
    min-height: 35em;
  }

  @media ${media.xlarge} {
    min-height: 40em;
    h1 {
      font-size: 6em;
    }
  }

  @media ${media.xxlarge} {
    h1 {
      font-size: 7em;
      position: relative;
      z-index: ${zLevels[3]};
    }
  }

  @media ${media.max} {
    grid-column-start: edge-left;
    grid-column-end: edge-right;
  }
`

const StyledSpecial = styled.div`
  width: 100%;
  h1 {
    font-size: 1.5em;
  }
  @media only screen and (min-width: 375px) {
    h1 {
      font-size: 2.5em;
    }
  }
`

const HeroImage = ({ content }) => {
  return (
    <StyledWrapper>
      <Image url={content.primary?.background_image?.url} size="cover" />
      <Heading size="h1" theme="gray" align="center">
        {content.primary?.header}
      </Heading>
      <StyledSpecial>
        <Heading size="special" theme="black" align="center">
          {content.primary?.payoff}
        </Heading>
      </StyledSpecial>
    </StyledWrapper>
  )
}

export default HeroImage
