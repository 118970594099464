import React from "react"
import styled from "styled-components"
import { spacing, media } from "../../../constants"
import { Heading } from "../../typography"
import { useViewport } from "../../../utils"

const StyledWrapper = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  margin-bottom: ${spacing.medium};
  margin-top: ${spacing.medium};
  display: grid;
  grid-gap: 2em;

  @media ${media.medium} {
    grid-column-start: outer-xx-left;
    grid-column-end: outer-xx-right;
    grid-template-columns: 1fr max-content;
    justify-self: center;
  }
`
const StyledText = styled.div`
  @media ${media.medium} {
    align-self: center;
    max-width: 30em;
    justify-self: right;
  }
`
const StyledIframe = styled.div`
  display: grid;
  align-self: center;
  justify-self: center;

  @media ${media.medium} {
    justify-self: left;
    iframe {
      justify-self: left;
    }
  }
`

const EmbedSpotifyPlaylist = ({ content }) => {
  return (
    <StyledWrapper>
      <StyledText>
        <Heading
          size="h3"
          theme={content.theme}
          align={useViewport().index >= 1 ? "left" : "center"}
        >
          {content.primary.header}
        </Heading>
      </StyledText>
      <StyledIframe>
        <iframe
          src={`https://open.spotify.com/embed/playlist/${content.primary.playlist_id}`}
          width="300"
          height="380"
          frameBorder="0"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </StyledIframe>
    </StyledWrapper>
  )
}

export default EmbedSpotifyPlaylist
