import React from "react"
import TestimonialBig from "./TestimonialBig"
import TestimonialCarouselBig from "./TestimonialCarouselBig"

const Testimonial = ({ content, type }) => {
  return (
    <>
      {type === "big" && <TestimonialBig content={content} />}
      {type === "carousel_big" && <TestimonialCarouselBig content={content} />}
    </>
  )
}

export default Testimonial
