import React from "react"
import styled from "styled-components"
import Heading from "../../typography/Heading"
import { media } from "../../../constants/breakpoints"
import Image from "../../media/image"
import Video from "../../media/video"
import { zLevels } from "../../../constants/grid"
import { colors } from "../../../constants/colors"

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  grid-column-start: 2;
  grid-column-end: 9;
  background-color: grey;
  min-height: 40em;
  display: grid;
  align-items: center;
  justify-items: center;
  > * {
    grid-area: 1/1/2/2;
  }

  @media ${media.xxlarge} {
    h1 {
      font-size: 5em;
      position: relative;
      z-index: ${zLevels[3]};
    }
  }
`

const HeroVideo = ({ data }) => {
  const linkType = data.media_link._linkType
  return (
    <StyledWrapper>
      {linkType === "Link.image" && (
        <Image url={data.media_link.url} size="cover" />
      )}
      {linkType === "Link.file" && (
        <Video url={data.media_link.url} hasOverlay={true} />
      )}
      <Heading size="h1" theme={colors} align="center">
        {data.header[0].text}
      </Heading>
    </StyledWrapper>
  )
}

export default HeroVideo
