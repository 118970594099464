import React from "react"
import styled from "styled-components"
import { zLevels } from "../../constants/grid"

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  > * {
    grid-area: 1/1/2/2;
  }
`

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: ${zLevels[1]};
`

// const StyledOverlay = styled.div`
//   width: 100%;
//   height: 100%;
//   background-image: url(${props => props.url});
//   background-repeat: repeat;
//   background-size: 30px 30px;
//   position: relative;
//   z-index: ${zLevels[2]};
// `

const Video = ({ url, hasOverlay }) => {
  return (
    <StyledWrapper>
      <StyledVideo autoPlay muted loop>
        <source src={url} type="video/mp4" />
      </StyledVideo>
    </StyledWrapper>
  )
}

export default Video
