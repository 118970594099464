import React from "react"
import styled from "styled-components"
import { Body } from "../../typography"
import Heading from "../../typography/Heading"
import Icon from "../../../icon"
import { spacing } from "../../../constants/grid"

const StyledWrapper = styled.div`
  grid-column-start: outer-xx-left;
  grid-column-end: outer-xxx-right;
  display: grid;
  grid-gap: 6em;
  grid-template-columns: repeat(auto-fill, minmax(18.5em, 1fr));
  margin-bottom: ${spacing.large};
  margin-top: ${spacing.small};
`
const StyledColumnWrapper = styled.div`
  display: grid;
  grid-template-rows: max-content max-content max-content;
  grid-gap: 1em;
`

const GridStandard = ({ content }) => {
  return (
    <StyledWrapper>
      {content.fields.map((field, i) => (
        <StyledColumnWrapper key={i}>
          <Icon icon={field.icon} theme={content.theme} size="medium" />
          <Heading size="h3">{field.header}</Heading>
          <Body>{field.paragraph}</Body>
        </StyledColumnWrapper>
      ))}
    </StyledWrapper>
  )
}

export default GridStandard
