import React from "react"
import styled from "styled-components"
import Image from "../../../uiKit/Image"
import { Body } from "../../typography"
import Heading from "../../typography/Heading"
import { Person } from "../Person"
import { media } from "../../../constants/breakpoints"
import { colors } from "../../../constants/colors"
import { spacing } from "../../../constants/grid"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useViewport } from "../../../utils"

const StyledWrapper = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  display: grid;
  grid-template-rows: 1fr;
  color: ${colors.white};
  position: relative;
  margin-bottom: ${spacing.medium};
  margin-top: ${spacing.medium};

  @media ${media.large} {
    color: black;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4em;
    min-height: 35em;
  }
`
const StyledColumn = styled.div`
  grid-area: 1/1/2/2;
  position: relative;

  @media ${media.large} {
    justify-self: ${props => (props.direction === "left" ? "right" : "left")};
    display: grid;
    grid-area: auto;
    width: 25em;
    &:last-of-type {
      justify-self: ${props => (props.direction === "left" ? "left" : "right")};
      grid-area: ${props =>
        props.direction === "left" ? "1/2/2/3" : "1/1/2/2"};
    }
  }
`
const StyledTextWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  padding: 2em;
  color: ${colors[process.env.THEME].white};
  max-width: 25em;
  margin: auto;
  @media ${media.large} {
    color: ${colors[process.env.THEME].black};
    align-self: center;
  }
`

const TestimonialBig = ({ content }) => {
  const { primary } = content
  const breakpoints = useBreakpoint()
  const viewport = useViewport()

  return (
    <StyledWrapper>
      <StyledColumn direction={primary.direction}>
        <Image
          url={primary.image?.url}
          darken={breakpoints.large ? false : true}
          style={process.env.THEME}
          glitch
        />
      </StyledColumn>

      <StyledColumn direction={primary.direction}>
        <StyledTextWrapper viewport={viewport}>
          <Heading>{primary.header}</Heading>
          <Body italic>"{primary.paragraph}"</Body>
          {primary.author && <Person content={primary.author} mobileLeft />}
        </StyledTextWrapper>
      </StyledColumn>
    </StyledWrapper>
  )
}

export default TestimonialBig
