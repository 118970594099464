import React from "react"
import GridPeople from "./GridPeople"
import GridStandard from "./GridStandard"

const Grid = ({ content, type }) => {
  return (
    <>
      {type === "standard" && <GridStandard content={content} />}
      {type === "people" && <GridPeople content={content} />}
    </>
  )
}

export default Grid
