import React from "react"
import PartnersStandard from "./PartnersStandard"
import PartnersGrid from "./PartnersGrid"

const Partners = ({ content, type }) => {
  return (
    <>
      {type === "standard" && <PartnersStandard content={content} />}
      {type === "grid" && <PartnersGrid content={content} />}
    </>
  )
}
export default Partners
