import React from "react"
import styled from "styled-components"
import Image from "../../media/image"
import Heading from "../../typography/Heading"
import { media } from "../../../constants/breakpoints"
import { Body } from "../../typography"
import { getColPadding } from "../../../constants/grid"
import { Person } from "../Person"

const StyledWrapper = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  > h5 {
    @media ${media.medium} {
      padding-right: ${getColPadding("outer-xxx", "medium")};
    }
    @media ${media.large} {
      grid-column-start: outer-xx-left;
      padding: 0;
    }
  }
`

const StyledColumnsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5em;
  @media ${media.medium} {
    grid-template-columns: ${props =>
      props.columns === 2 ? "1fr 1fr" : "1fr"};
  }
  @media ${media.large} {
    grid-gap: 4em;
    grid-template-columns: ${props =>
      props.columns === 3 ? "1fr 1fr 1fr" : "1fr 1fr"};
  }
`

const StyledHeader = styled.div`
  margin-bottom: 2em;
`
const StyledColumnWrapper = styled.div`
  display: grid;
  height: 25em;
  /* max-width: 25em; */
  /* margin: auto; */
  > div {
    grid-area: 1/1/2/2;
  }
`
const StyledCountry = styled.div`
  grid-area: 1/1/3/2;
  height: 100%;
  width: 100%;
  opacity: 0.15;
  margin: auto;
`

const StyledTextWrapper = styled.div`
  grid-area: 1/1/3/2;
  height: max-content;
  align-self: center;
  display: grid;
  grid-gap: 1em;
  max-width: 20em;
  margin: auto;
  * {
    text-align: center;
  }
  @media ${media.large} {
    * {
      text-align: left;
    }
  }
`

const UspTravelDestinations = ({ content }) => {
  return (
    <StyledWrapper>
      <StyledHeader>
        <Heading size="h5" align="center">
          {content.primary.header}
        </Heading>
      </StyledHeader>
      <StyledColumnsWrapper columns={content.fields.length}>
        {content.fields.map((destination, i) => (
          <StyledColumnWrapper key={i}>
            <StyledCountry>
              <Image url={destination.country_image?.url} size="contain" />
            </StyledCountry>
            <StyledTextWrapper>
              <Heading size="h3">{destination.header}</Heading>
              <Body>{destination.paragraph}</Body>
              {destination.team_leader && (
                <Person content={destination.team_leader} />
              )}
            </StyledTextWrapper>
          </StyledColumnWrapper>
        ))}
      </StyledColumnsWrapper>
    </StyledWrapper>
  )
}

export default UspTravelDestinations
