import React, { useRef } from 'react';
import styled from 'styled-components';
import TextField from '../../../uiKit/TextField';
import Button from '../../../uiKit/Button';
import Image from '../../../uiKit/Image';
import { media } from '../../../constants/breakpoints';
import { Heading } from '../../typography';
import { useViewport } from '../../../utils';
import ClassPicker from '../../inputs/ClassPicker';

const StyledWrapper = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  display: grid;
  @media ${media.large} {
    column-gap: 4em;
    row-gap: 2em;
  }
`;
const StyledForm = styled.form`
  width: 100%;
  height: 100%;
`;
const StyledButtonWrapper = styled.div`
  width: max-content;
  margin: auto;
  margin-top: 2em;
  @media ${media.medium} {
    grid-area: 5/1/6/3;
  }
  @media ${media.large} {
    grid-area: auto;
    align-self: center;
    justify-self: center;
    margin: 0;
  }
`;
const StyledHeading = styled.div`
  margin-top: 2em;
  margin-bottom: 2em;
  @media ${media.large} {
    margin-bottom: 0em;
    grid-area: 1/1/2/2;
    justify-self: left;
  }
`;
const StyledImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  min-width: 12em;
  max-width: 30em;
  max-height: 100%;
  min-height: 30em;
  grid-area: 1/2/3/3;
  justify-self: right;
`;
const StyledFieldsWrapper = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  margin-bottom: 4em;
  column-gap: 2em;
  @media ${media.medium} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${media.large} {
    justify-items: left;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 2em;
    width: max-content;
    grid-area: 4/1/5/2;
  }
`;

const StyledInputs = styled.div`
  display: grid;
  grid-gap: 2em;
`;

const LgSignupForm = ({ content }) => {
  const { primary } = content;
  const viewportIndex = useViewport().index;

  const handleRadio = (index, formChildren) => {
    let radioValid = false;

    const radioWrappers = Array.from(
      formChildren[2].children[index].children[0].children[1].children
    );

    radioWrappers.forEach((child, i) => {
      const input = child.children[0];

      if (input.checked) {
        radioValid = true;
      }

      if (radioWrappers.length === i + 1) {
        if (!input.checked && !radioValid) {
          formChildren[2].children[index].children[0].children[1].setAttribute(
            'data-valid',
            false
          );
        } else {
          formChildren[2].children[index].children[0].children[1].setAttribute(
            'data-valid',
            true
          );
        }
      }
    });

    return radioValid;
  };

  const handleInvalid = (e) => {
    e.preventDefault();
    const formChildren = Array.from(e.currentTarget.children);

    // The index of where the radios are in the DOM
    handleRadio(0, formChildren);
    handleRadio(1, formChildren);



    let fields = []
    // If length is 4 then 10/11 class has been chosen and the travel 1. and 2. priority are rendered.
    // If length is 3 the travel 1. and 2. priority are not rendered.
    if(formChildren[2].children.length === 4){
       fields = Array.from(formChildren[2].children[3].children);
    }else{
       fields = Array.from(formChildren[2].children[2].children);
    }
    fields.forEach((child) => {
      if (child.children.length >= 2) {
        const input = child.children[1];
        input.setAttribute('data-valid', input.validity.valid);
      }
    });
    
  };

  return (
    <StyledWrapper>
      <StyledHeading>
        <Heading size="h2" align="center" theme="blue">
          {primary.header}
        </Heading>
      </StyledHeading>
      <StyledForm
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="signup"
        onInvalid={handleInvalid}
        action={'/tilmeld-success'}
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="signup" value="signup" />

        <StyledInputs>
          <ClassPicker />
        
          <StyledFieldsWrapper>
            <TextField
              name="studentName"
              label={primary.student_name_label}
              placeholder={primary.student_name_placeholder}
              style={process.env.THEME}
              type="text"
              required
            />
            <TextField
              name="studentPhone"
              label={primary.student_phone_label}
              placeholder={primary.student_phone_placeholder}
              style={process.env.THEME}
              pattern="[0-9]{8}"
              type="tel"
              required
            />
            <TextField
              name="studentMail"
              label={primary.student_mail_label}
              placeholder={primary.student_mail_placeholder}
              style={process.env.THEME}
              type="email"
              required
            />
            <TextField
              name="parentsNames"
              label={primary.parents_names_label}
              placeholder={primary.parents_names_placeholder}
              style={process.env.THEME}
              type="text"
              required
            />
            <TextField
              name="parentsPhone"
              label={primary.parents_phone_label}
              placeholder={primary.parents_phone_placeholder}
              style={process.env.THEME}
              pattern="[0-9]{8}"
              type="tel"
              required
            />
            <TextField
              name="parentsMail"
              label={primary.parents_mail_label}
              placeholder={primary.parents_mail_placeholder}
              style={process.env.THEME}
              type="email"
              required
            />
            <TextField
              name="address"
              label={primary.address_label}
              placeholder={primary.address_placeholder}
              style={process.env.THEME}
              type="text"
              required
            />
            <TextField
              name="commentary"
              label={primary.commentary_label}
              placeholder={primary.commentary_placeholder}
              style={process.env.THEME}
              type="text"
            />
            <StyledButtonWrapper>
              <Button
                type="button"
                style={process.env.THEME}
                theme="blue"
                size="big"
              >
                {primary.button_label}
              </Button>
            </StyledButtonWrapper>
          </StyledFieldsWrapper>
        </StyledInputs>
      </StyledForm>

      {viewportIndex >= 3 && (
        <StyledImageWrapper>
          <Image style={process.env.THEME} url={primary.image.url} />
        </StyledImageWrapper>
      )}
    </StyledWrapper>
  );
};

export default LgSignupForm;
