import React from "react"
import styled from "styled-components"
import { spacing, fontFamily, colors, media } from "../../../constants"
import { Heading } from "../../typography"
import { useViewport } from "../../../utils"

const StyledWrapper = styled.div`
  grid-column-start: outer-xx-left;
  grid-column-end: outer-xx-right;
  position: relative;
  margin-bottom: ${spacing.medium};
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: ${spacing.medium};
  @media ${media.large} {
    grid-column-start: outer-xxx-left;
    grid-column-end: outer-xxx-right;
  }
`

const StyledLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content;
  grid-gap: 2em;
  @media ${media.medium} {
    grid-template-columns: repeat(2, max-content);
  }
  @media ${media.large} {
    width: max-content;
    margin: auto;
    grid-template-columns: repeat(4, max-content);
  }
`
const StyledLinkWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 0.2em;
`

const StyledLink = styled.a`
  font-family: ${fontFamily[process.env.THEME].headingItalic};
  text-decoration: none;
  color: ${colors[process.env.THEME].pink};
  font-size: 2em;
  position: relative;
  width: max-content;
`

const StyledArrow = styled.div`
  position: relative;
  font-size: 2em;
  width: 1em;
  height: 1em;
  &:after,
  &:before {
    content: "";
    position: absolute;
    background-color: ${props => colors[process.env.THEME].pink};
    width: 0.14em;
    height: 0.6em;
    border-radius: 0px;
    left: 0;
  }
  &:before {
    bottom: 50%;
    left: -50%;
    transform: translateX(1.2em) rotate(-45deg);
    transform-origin: 100% 100%;
  }

  &:after {
    top: 55%;
    left: -50%;
    transform: translateX(1.2em) rotate(45deg);
    transform-origin: 100% 0;
  }
`

const TeaserLinkList = ({ content }) => {
  const { primary } = content

  const links = [
    {
      label: primary.link_label_1,
      url: primary.link_url_1?.path,
    },
    {
      label: primary.link_label_2,
      url: primary.link_url_2?.path,
    },
    {
      label: primary.link_label_3,
      url: primary.link_url_3?.path,
    },
    {
      label: primary.link_label_4,
      url: primary.link_url_4?.path,
    },
  ]
  return (
    <StyledWrapper>
      <Heading align={useViewport().index >= 2 ? "center" : "left"}>
        {primary.header}
      </Heading>
      <StyledLinksWrapper>
        {links.map((link, i) => (
          <StyledLinkWrapper key={i}>
            <StyledLink href={link.url}>{link.label}</StyledLink>
            <StyledArrow />
          </StyledLinkWrapper>
        ))}
      </StyledLinksWrapper>
    </StyledWrapper>
  )
}

export default TeaserLinkList
