import React from "react"
import MediaVideo from "./MediaVideo"
import MediaImage from "./MediaImage"

const Media = ({ content, type }) => {
  return (
    <>
      {type === "video" && <MediaVideo content={content} />}
      {type === "image" && <MediaImage content={content} />}
    </>
  )
}

export default Media
