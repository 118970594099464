import React from "react"
import ParagraphStandard from "./ParagraphStandard"
import ParagraphIntro from "./ParagraphIntro"

const Paragraph = ({ content, type }) => {
  return (
    <>
      {type === "standard" && <ParagraphStandard content={content} />}
      {type === "intro" && <ParagraphIntro content={content} />}
    </>
  )
}

export default Paragraph
