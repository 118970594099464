import React from "react"
import { Body, Heading } from "../../typography"
import styled from "styled-components"
import { spacing } from "../../../constants/grid"
import Button from "../../../uiKit/Button"
import { media } from "../../../constants/breakpoints"

const StyledWrapper = styled.div`
  text-align: center;
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  margin-bottom: ${spacing.medium};
  margin-top: ${spacing.medium};
  gap: 0.5em;
  display: flex;
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: 0.5em;
  }

  @media ${media.medium} {
    grid-column-start: outer-x-left;
    grid-column-end: outer-x-right;
  }
  @media ${media.large} {
    grid-column-start: inner-left;
    grid-column-end: inner-right;
  }
`

const TeaserParagraph = ({ content }) => {
  const { primary } = content

  return (
    <StyledWrapper>
      {primary.header && (
        <Heading size="h4" align="center">
          {primary.header}
        </Heading>
      )}
      <Body size={"big"} theme={content.theme} align="center">
        {primary.paragraph}
      </Body>

      <Button
        type={"a"}
        url={
          primary.button_link?._linkType === "Link.web"
            ? primary.button_link.url
            : primary.button_link.path
        }
        target={primary.button_link?.target}
        theme={content.theme}
        style={process.env.THEME}
        center
      >
        {primary.button_label}
      </Button>
    </StyledWrapper>
  )
}

export default TeaserParagraph
