import React from "react"
import styled from "styled-components"
import { colors } from "../../../constants/colors"
import { Body, Heading } from "../../typography"
const StyledWrapper = styled.div`
  margin-top: -4em;
  grid-column-start: outer-xx-left;
  grid-column-end: outer-xx-right;
`

const StyledBody = styled.div`
  margin-top: 1em;
`
const StyledHeader = styled.div`
  margin-top: 2em;
  margin-bottom: 1em;
`
const StyledLine = styled.div`
  width:2em;
  height:0.3em;
  margin-top:0.6em;
  background-color:${colors[process.env.THEME].blue};
`
const StyledItem = styled.div`
  display:grid;
  margin-top:0.5em;
  grid-gap:1em;
  grid-template-columns:max-content 1fr;
`

const DocumentsLegalText = ({ content }) => {
  const { primary } = content
  return (
    <StyledWrapper>
      {primary.legal_text.map((item, i) => {
        if(item.type === "list-item"){
          return (
            <StyledItem>
              <StyledLine />
              <Body>{item.text}</Body>
            </StyledItem>
          )
        }
        if (item.type === "paragraph") {
          return (
            <StyledBody key={i}>
              <Body>{item.text}</Body>
            </StyledBody>
          )
        }
        if (item.type === "heading1") {
          return (
            <StyledHeader key={i}>
              <Heading size="h1">{item.text}</Heading>
            </StyledHeader>
          )
        }
        if (item.type === "heading2") {
          return (
            <StyledHeader key={i}>
              <Heading size="h2">{item.text}</Heading>
            </StyledHeader>
          )
        }
        if (item.type === "heading3") {
          return (
            <StyledHeader key={i}>
              <Heading size="h3">{item.text}</Heading>
            </StyledHeader>
          )
        }
        if (item.type === "heading4") {
          return (
            <StyledHeader key={i}>
              <Heading size="h4">{item.text}</Heading>
            </StyledHeader>
          )
        }
        if (item.type === "heading5") {
          return (
            <StyledHeader key={i}>
              <Heading size="h5">{item.text}</Heading>
            </StyledHeader>
          )
        }
        return null
      })}
    </StyledWrapper>
  )
}

export default DocumentsLegalText
