import React from "react"
import styled from "styled-components"
import { Body } from "../../typography"
import Heading from "../../typography/Heading"
import { spacing } from "../../../constants/grid"
import Image from "../../../uiKit/Image"
import { media } from "../../../constants"

const StyledWrapper = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  margin-bottom: ${spacing.large};
  margin-top: ${spacing.medium};
`
const StyledColumnsWrapper = styled.div`
  display: grid;
  grid-gap: 4em;
  grid-template-columns: repeat(2, 10em);
  margin-top: ${spacing.medium};
  width: max-content;
  margin-left: auto;
  margin-right: auto;

  @media ${media.medium} {
    grid-template-columns: repeat(3, 10em);
  }
  @media ${media.large} {
    grid-template-columns: repeat(4, 10em);
  }
  @media ${media.xlarge} {
    grid-template-columns: repeat(5, 10em);
  }
  @media ${media.xxlarge} {
    grid-column-start: edge-left;
    grid-column-end: edge-right;
    grid-template-columns: repeat(6, 10em);
  }
`
const StyledImageWrapper = styled.div`
  width: 9em;
  height: 9em;
  margin: auto;
`
const StyledColumnWrapper = styled.div`
  display: grid;
  grid-template-rows: 10em max-content max-content;
  > div {
    margin-bottom: 1em;
  }
`

const GridPeople = ({ content }) => {
  return (
    <StyledWrapper>
      <Heading align="center">{content.primary?.title}</Heading>
      <StyledColumnsWrapper>
        {content.fields.map(({ person }, i) => (
          <StyledColumnWrapper key={i}>
            <StyledImageWrapper>
              <Image
                url={person.portrait?.url}
                style={process.env.THEME}
                round
              />
            </StyledImageWrapper>
            <Heading align="center" size="h5">
              {person.first_name} {person.last_name}
            </Heading>
            <Body align="center">{person.position}</Body>
            <Body align="center" size="small">
              {person.mail}
            </Body>
          </StyledColumnWrapper>
        ))}
      </StyledColumnsWrapper>
    </StyledWrapper>
  )
}

export default GridPeople
