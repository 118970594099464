import React from "react"
import LgSignupTeaser from "./LgSignupTeaser"
import LgSignupForm from "./LgSignupForm"

const LeadGenerator = ({ content, type }) => {
  return (
    <>
      {type === "signup_teaser" && <LgSignupTeaser content={content} />}
      {type === "signup_form" && <LgSignupForm content={content} />}
    </>
  )
}

export default LeadGenerator
