import React from "react"
import TeaserImage from "./TeaserImage"
import TeaserParagraph from "./TeaserParagraph"
import TeaserLinkList from "./TeaserLinkList"

const Teaser = ({ content, type }) => {
  return (
    <>
      {type === "image" && <TeaserImage content={content} />}
      {type === "paragraph" && <TeaserParagraph content={content} />}
      {type === "link_list" && <TeaserLinkList content={content} />}
    </>
  )
}

export default Teaser
