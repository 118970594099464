import React from "react"
import styled from "styled-components"
import Heading from "../../typography/Heading"
import { spacing } from "../../../constants/grid"
import Image from "../../media/image"
import { Body } from "../../typography"
import { getColPadding } from "../../../constants/grid"
import { media } from "../../../constants/breakpoints"

const StyledWrapper = styled.div`
  grid-column-start: edge-left;
  grid-column-end: edge-right;
  width: 100%;
  height: 100%;
  margin-bottom: ${spacing.medium};
  margin-top: ${spacing.medium};
  h5 {
    width: max-content;
    margin: auto;
  }
`

const SliderWrapper = styled.div`
  margin-top: ${spacing.small};
  display: flex;
  grid-auto-columns: max-content;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  grid-gap: 2em;
  width: 100%;
  min-height: 7em;
  &::-webkit-scrollbar {
    display: none;
  }
  p {
    margin-top: 1em;
  }
  @media ${media.xlarge} {
    margin-left: auto;
    margin-right: auto;
    width: max-content;
  }
`
const StyledPartner = styled.div`
  width: 4em;
  scroll-snap-align: start;
  &:first-of-type {
    padding-left: ${getColPadding("outer-xxx", "small")};
    box-sizing: content-box;
    @media ${media.medium} {
      padding-left: ${getColPadding("outer-xxx", "medium")};
    }
    @media ${media.large} {
      padding-left: ${getColPadding("outer-xxx", "large")};
    }
    @media ${media.xxlarge} {
      padding-left: ${getColPadding("outer-xxx", "xxlarge")};
    }
    @media ${media.max} {
      padding-left: ${getColPadding("outer-xxx", "max")};
    }
  }
  &:last-of-type {
    padding-right: ${getColPadding("outer-xxx", "small")};
    box-sizing: content-box;
    @media ${media.medium} {
      padding-right: ${getColPadding("outer-xxx", "medium")};
    }
    @media ${media.large} {
      padding-right: ${getColPadding("outer-xxx", "large")};
    }
    @media ${media.xxlarge} {
      padding-right: ${getColPadding("outer-xxx", "xxlarge")};
    }
    @media ${media.max} {
      padding-right: ${getColPadding("outer-xxx", "max")};
    }
  }
`
const StyledLogo = styled.div`
  width: 3em;
  height: 3em;
  margin: auto;
`

const PartnersStandard = ({ content }) => {
  return (
    <StyledWrapper>
      <Heading size="h5">{content.primary.header}</Heading>
      <SliderWrapper>
        {content.fields.map((partner, i) => (
          <StyledPartner key={i}>
            <StyledLogo>
              <Image url={partner.partner_logo?.url} size="contain" />
            </StyledLogo>

            <Body size="small" align="center">
              {partner.partner_name}
            </Body>
          </StyledPartner>
        ))}
      </SliderWrapper>
    </StyledWrapper>
  )
}
export default PartnersStandard
