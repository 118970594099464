import React from "react"
import styled from "styled-components"
import { spacing, media } from "../../../constants"
import { Heading } from "../../typography"
import { useViewport } from "../../../utils"

const StyledWrapper = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  margin-bottom: ${spacing.medium};
  margin-top: ${spacing.medium};
  display: grid;
  grid-gap: 2em;

  @media ${media.medium} {
    grid-column-start: outer-xx-left;
    grid-column-end: outer-xx-right;
    grid-template-columns: 1fr max-content;
  }
`
const StyledText = styled.div``
const StyledIframe = styled.div`
  display: grid;
  align-self: center;
  justify-self: center;
  iframe {
    width: 20em;
    height: 6em;
  }
`

const EmbedSpotifyFollow = ({ content }) => {
  return (
    <StyledWrapper>
      <StyledText>
        <Heading
          size="h3"
          theme={content.theme}
          align={useViewport().index >= 1 ? "left" : "center"}
        >
          {content.primary.header}
        </Heading>
      </StyledText>
      <StyledIframe>
        <iframe
          title={content.primary.artist_name}
          src={`https://open.spotify.com/follow/1/?uri=spotify:artist:${content.primary.artist_id}&size=detail&theme=light`}
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          frameborder="0"
          background
        />
      </StyledIframe>
    </StyledWrapper>
  )
}

export default EmbedSpotifyFollow
