import React from "react"
import UspTravelDestinations from "./UspTravelDestinations"
import UspStandard from "./UspStandard"
import UspStandardExtended from "./UspStandardExtended"

const Usp = ({ content, type }) => {
  return (
    <>
      {type === "standard" && <UspStandard content={content} />}
      {type === "standard_extended" && (
        <UspStandardExtended content={content} />
      )}
      {type === "travel_destinations" && (
        <UspTravelDestinations content={content} />
      )}
    </>
  )
}

export default Usp
