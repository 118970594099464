import React from "react"
import TextFieldAlterna from "./TextFieldAlterna"

const TextField = props => {
  const { style } = props
  return (
    <>
      {style === "alterna" && (
        <TextFieldAlterna {...props}>{props.children}</TextFieldAlterna>
      )}
    </>
  )
}

export default TextField
