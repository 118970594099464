import React, { useState } from 'react';
import styled from 'styled-components';
import { zLevels } from '../../../constants/grid';
import { spacing } from '../../../constants/grid';
import { media } from '../../../constants/breakpoints';

const StyledWrapper = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  display: grid;
  margin-bottom: ${spacing.large};
  margin-top: ${spacing.large};
  position: relative;
  @media ${media.medium} {
    grid-column-start: outer-xx-left;
    grid-column-end: outer-xx-right;
  }
  @media ${media.large} {
    grid-column-start: outer-x-left;
    grid-column-end: outer-x-right;
  }

  svg {
    grid-area: 1/1/2/2;
    align-self: center;
    justify-self: center;
    top: 0;
    left: 0;
    z-index: ${zLevels[3]};
    width: 10em;
    height: 10em;
  }
`;

const StyledPlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  grid-area: 1/1/2/2;
`;
const StyledInnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;
const StyledVideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: ${zLevels[1]};
`;

const StyledSvg = styled.svg`
  display: ${(props) => (props.show ? 'block' : 'none')};
`;

const MediaVideo = ({ content }) => {
  const [play, setPlay] = useState(false);
  const videoRef = React.createRef();
  const changePlayState = () => {
    setPlay(!play);
    if (!play) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  return (
    <StyledWrapper onClick={() => changePlayState()}>
      <StyledPlayerWrapper>
        <StyledInnerWrapper>
          <StyledVideoWrapper>
            <StyledVideo ref={videoRef} poster={content.primary.poster?.url}>
              <source src={content.primary.video_url.url} type="video/mp4" />
            </StyledVideo>
          </StyledVideoWrapper>
        </StyledInnerWrapper>
      </StyledPlayerWrapper>
      <StyledSvg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/StyledSvg"
        show={!play}
      >
        <path d="M15 12.3301L9 16.6603L9 8L15 12.3301Z" fill="white" />
      </StyledSvg>
    </StyledWrapper>
  );
};

export default MediaVideo;
