import React from "react"
import styled from "styled-components"
import { Body, Heading } from "../../typography"
import Icon from "../../../icon"
import { spacing } from "../../../constants/grid"
import { media } from "../../../constants/breakpoints"

const StyledWrapper = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  display: grid;
  grid-gap: 6em;
  margin-bottom: ${spacing.medium};
  margin-top: ${spacing.small};
  grid-template-columns: repeat(auto-fill, minmax(14em, 1fr));
  @media ${media.large} {
    grid-column-start: outer-xx-left;
  }
`
const StyledColumnWrapper = styled.div`
  display: grid;
  grid-template-rows: max-content max-content max-content;
  grid-gap: 1em;
  max-width: 20em;
  margin: auto;
`
const StyledParagraphWrapper = styled.div``

const UspStandard = ({ content }) => {
  return (
    <StyledWrapper>
      {content.fields.map((field, i) => (
        <StyledColumnWrapper key={i}>
          <Icon
            icon={field.icon}
            theme={content.theme}
            size={content.primary?.icon_size}
          />
          <Heading size="h4">{field.header}</Heading>
          <StyledParagraphWrapper>
            <Body>{field.paragraph}</Body>
          </StyledParagraphWrapper>
        </StyledColumnWrapper>
      ))}
    </StyledWrapper>
  )
}

export default UspStandard
