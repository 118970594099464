import React from 'react';
import Layout from '../components/global/Layout';
import Hero from '../components/blocks/Hero';
import Paragraph from '../components/blocks/Paragraph';
import Teaser from '../components/blocks/Teaser';
import Usp from '../components/blocks/Usp';
import Testimonial from '../components/blocks/Testimonial';
import Partners from '../components/blocks/Partners';
import LeadGenerator from '../components/blocks/LeadGenerator';
import Grid from '../components/blocks/Grid';
import InfoBox from '../components/blocks/InfoBox';
import Documents from '../components/blocks/Documents';
import Values from '../components/blocks/Values';
import Media from '../components/blocks/Media';
import Embed from '../components/blocks/Embed';
import { Helmet } from 'react-helmet';

const Page = (data) => {
  const content = data.pageContext.content;
  const layout = data.pageContext.layout;

  const announcement = {
    text: content.announcement_text,
    target: content.announcement_page_link?.target,
    url:
      content.announcement_page_link?._linkType === 'Link.document'
        ? content.announcement_page_link?.path
        : content.announcement_page_link?.url,
    theme: content.announcement_theme,
    pageId: layout._meta.uid,
  };

  return (
    <>
      <Helmet>
        <title>{content?.meta_title}</title>
        <meta name="description" content={content?.meta_description} />
      </Helmet>
      <Layout
        data={layout}
        pageTitle={content.page_title}
        showPageTitle={content.show_page_title}
        showPageShortDescription={content.show_page_description}
        pageShortDescription={content.page_short_description}
        announcement={announcement}
      >
        {content.block_links && (
          <>
            {content.block_links.map((block, i) => {
              if (block.link && block.link.body) {
                const blockType = block.link._meta.type;
                const blockSlice = block.link.body[0].type;
                const blockContent = {
                  primary: block.link.body[0].primary,
                  fields: block.link.body[0].fields,
                  theme: block.link.theme,
                };

                if (blockType === 'hero') {
                  return (
                    <Hero key={i} content={blockContent} type={blockSlice} />
                  );
                } else if (blockType === 'paragraph') {
                  return (
                    <Paragraph
                      key={i}
                      content={blockContent}
                      type={blockSlice}
                    />
                  );
                } else if (blockType === 'teaser') {
                  return (
                    <Teaser key={i} content={blockContent} type={blockSlice} />
                  );
                } else if (blockType === 'partners') {
                  return (
                    <Partners
                      key={i}
                      content={blockContent}
                      type={blockSlice}
                    />
                  );
                } else if (blockType === 'usp') {
                  return (
                    <Usp key={i} content={blockContent} type={blockSlice} />
                  );
                } else if (blockType === 'testimonial') {
                  return (
                    <Testimonial
                      key={i}
                      content={blockContent}
                      type={blockSlice}
                    />
                  );
                } else if (blockType === 'grid') {
                  return (
                    <Grid key={i} content={blockContent} type={blockSlice} />
                  );
                } else if (blockType === 'info_box') {
                  return (
                    <InfoBox key={i} content={blockContent} type={blockSlice} />
                  );
                } else if (blockType === 'documents') {
                  return (
                    <Documents
                      key={i}
                      content={blockContent}
                      type={blockSlice}
                    />
                  );
                } else if (blockType === 'lead_generator') {
                  return (
                    <LeadGenerator
                      key={i}
                      content={blockContent}
                      type={blockSlice}
                    />
                  );
                } else if (blockType === 'values') {
                  return (
                    <Values key={i} content={blockContent} type={blockSlice} />
                  );
                } else if (blockType === 'media') {
                  return (
                    <Media key={i} content={blockContent} type={blockSlice} />
                  );
                } else if (blockType === 'embed') {
                  return (
                    <Embed key={i} content={blockContent} type={blockSlice} />
                  );
                }
              }
              return null;
            })}
          </>
        )}
      </Layout>
    </>
  );
};

export default Page;
