import React from "react"
import styled from "styled-components"
import { media } from "../../../constants/breakpoints"
import { Heading } from "../../typography"
import { shades } from "../../../constants/colors"
import { spacing } from "../../../constants/grid"
import { useEffect } from "react"

const StyledWrapper = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  display: grid;
  margin-bottom: ${spacing.medium};
  margin-top: ${spacing.medium};
  @media ${media.medium} {
    grid-column-start: outer-x-left;
    grid-column-end: outer-x-right;
  }
  @media ${media.large} {
    grid-column-start: inner-left;
    grid-column-end: inner-right;
  }
`

const StyledFade = styled.div`
  position: relative;
  grid-area: 1/1/2/2;
  background-image: linear-gradient(
    to top,
    white,
    rgba(255, 255, 255, 0),
    white
  );
`
const StyledScrollWrapper = styled.div`
  grid-area: 1/1/2/2;
  width: 100%;
  height: 5em;
  overflow: hidden;
`
const StyledStatements = styled.div`
  margin-top: 1.5em;
  position: relative;
`
const StyledStatementWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: margin 0.2s ease-in-out;
  margin-top: ${props => `${props.slideId * props.slideAmount}em`};
`

const StyledStatement = styled.div`
  height: 2em;
  color: ${props => (props.active ? shades.black : shades.gray40)};
  h3 {
    margin: auto;
    width: max-content;
  }
`

const ValueStatementAutoScroll = ({ content }) => {
  const statements = React.createRef()
  const slideDuration = 2
  let slide = 0
  let slideAmount = 2.5

  useEffect(() => {
    nextSlide()
    const interval = setInterval(() => {
      nextSlide()
    }, slideDuration * 1000)
    return () => clearInterval(interval)
  })

  const nextSlide = () => {
    const divs = document.querySelectorAll("[data-statement='true']")
    let nextSlide = slide + 1

    if (nextSlide > divs.length - 1) {
      nextSlide = 0
    }
    slide = nextSlide

    divs.forEach(div => {
      const divSlide = parseInt(div.getAttribute("data-slide"))
      const newSlidePos = divSlide - 1

      if (divSlide === -3 || divSlide === 3) {
        div.style.opacity = 0
      }
      if (divSlide === -1 || divSlide === 1) {
        div.style.opacity = 1
      }
      if (divSlide === 0) {
        div.firstChild.style.color = shades.black
        div.style.opacity = 1
      }

      div.style.marginTop = `${slideAmount * divSlide}em`

      if (newSlidePos === -4) {
        div.setAttribute("data-slide", 2)
      } else {
        div.setAttribute("data-slide", newSlidePos)
      }
    })
  }

  return (
    <StyledWrapper>
      <StyledScrollWrapper>
        <StyledStatements
          ref={statements}
          duration={slideDuration}
          slideAmount={slideAmount}
        >
          {content.fields.map(({ value_statement }, index) => (
            <StyledStatementWrapper
              key={index}
              data-statement="true"
              data-slide={index}
              slideId={index}
              slideAmount={slideAmount}
            >
              <StyledStatement active={index === 0}>
                <Heading size="h3" align="center">
                  {value_statement}
                </Heading>
              </StyledStatement>
            </StyledStatementWrapper>
          ))}
        </StyledStatements>
      </StyledScrollWrapper>
      <StyledFade />
    </StyledWrapper>
  )
}

export default ValueStatementAutoScroll
