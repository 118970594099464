import React from "react"
import styled from "styled-components"
import { media } from "../../../constants/breakpoints"
import { zLevels } from "../../../constants/grid"

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  grid-column-start: 2;
  grid-column-end: 9;
  background-color: grey;
  min-height: 40em;
  display: grid;
  align-items: center;
  justify-items: center;
  > * {
    grid-area: 1/1/2/2;
  }

  @media ${media.xxlarge} {
    h1 {
      font-size: 5em;
      position: relative;
      z-index: ${zLevels[3]};
    }
  }
`

const HeroColor = () => {
  return <StyledWrapper></StyledWrapper>
}

export default HeroColor
