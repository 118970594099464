import React from "react"
import styled from "styled-components"
import { Body, Heading } from "../../typography"
import Icon from "../../../icon"
import { media, spacing } from "../../../constants"
import { replaceEach } from "../../../utils"

const StyledWrapper = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  display: grid;
  grid-gap: 3em;
  grid-template-columns: 1fr 1fr;
  @media ${media.medium} {
    grid-column-start: outer-xx-left;
    grid-column-end: outer-xxx-right;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media ${media.large} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  margin-bottom: ${spacing.large};
  margin-top: ${spacing.small};
`
const StyledColumnWrapper = styled.div`
  display: grid;
  grid-template-rows: max-content max-content max-content;
  grid-gap: 1em;
`
const StyledParagraphWrapper = styled.div``

const UspStandardExtended = ({ content }) => {
  return (
    <StyledWrapper>
      {content.fields.map((field, i) => (
        <StyledColumnWrapper key={i}>
          <Icon
            icon={field.icon}
            theme={content.theme}
            size={content.primary?.icon_size}
          />
          <Heading size="h3">{field.header}</Heading>
          <StyledParagraphWrapper>
            <Body>
              <p
                dangerouslySetInnerHTML={{
                  __html: replaceEach(field.paragraph, "\n", "<br>"),
                }}
              ></p>
            </Body>
          </StyledParagraphWrapper>
        </StyledColumnWrapper>
      ))}
    </StyledWrapper>
  )
}

export default UspStandardExtended
