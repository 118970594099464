import React from "react"
import styled from "styled-components"
import { colors } from "../../../constants/colors"
import { media } from "../../../constants/breakpoints"
import { spacing } from "../../../constants/grid"
import { fontFamily } from "../../../constants/fonts"

const StyledWrapper = styled.div`
  grid-column-start: outer-xx-left;
  grid-column-end: outer-xx-right;
  position: relative;
  margin-bottom: ${spacing.medium};
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: ${colors[process.env.THEME].gray};
  }
`

const StyledLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em 7em;
  padding: 3em;
  width: max-content;
  margin: auto;

  @media ${media.medium} {
    grid-template-columns: 1fr 1fr;
  }
`

const StyledLink = styled.a`
  font-family: ${fontFamily[process.env.THEME].headingItalic};
  text-decoration: none;
  color: ${colors[process.env.THEME].pink};
  font-size: 2em;
  position: relative;
  width: max-content;
  &:after,
  &:before {
    content: "";
    position: absolute;
    right: 0;
    background-color: ${props => colors[process.env.THEME].pink};
    width: 0.14em;
    height: 0.6em;
    border-radius: 0px;
  }
  &:before {
    bottom: 50%;
    transform: translateX(1.2em) rotate(-45deg);
    transform-origin: 100% 100%;
  }

  &:after {
    top: 50%;
    transform: translateX(1.2em) rotate(45deg);
    transform-origin: 100% 0;
  }
`

const DocumentsList = ({ content }) => {
  return (
    <StyledWrapper>
      <StyledLinksWrapper>
        {content.fields.map((link, i) => (
          <>
            {(link.link_url?._linkType === "Link.web" ||
              link.link_url?._linkType === "Link.file") && (
              <StyledLink
                key={i}
                href={link.link_url.url}
                target={link.link_url.target}
              >
                {link.link_label}
              </StyledLink>
            )}
            {link.link_url?._linkType === "Link.document" && (
              <StyledLink key={i} href={link.link_url.path}>
                {link.link_label}
              </StyledLink>
            )}
          </>
        ))}
      </StyledLinksWrapper>
    </StyledWrapper>
  )
}

export default DocumentsList
