import React from "react"
import { Body } from "../../typography"
import Heading from "../../typography/Heading"
import styled, { css } from "styled-components"
import { getColPadding, spacing } from "../../../constants"
import Button from "../../../uiKit/Button"
import Image from "../../../uiKit/Image"
import { useViewport } from "../../../utils"

const StyledLink = styled.a`
  text-decoration: none;
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  margin-top: ${spacing.small};
  margin-bottom: ${spacing.small};
`

const StyledWrapper = styled.div`
  display: grid;
  min-height: 28.8em;
  align-items: center;
  justify-items: ${props => props.direction};
  width: 100%;
  height: 100%;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  padding-left: ${props => getColPadding(props.padding, props.viewport)};
  padding-right: ${props => getColPadding(props.padding, props.viewport)};

  &:hover {
    transform: scale(1.005);
    > div {
      ${props => {
        switch (props.direction) {
          case "center":
            return css`
              transform: translateY(0.5em);
            `

          case "right":
            return css`
              transform: translateX(0.5em);
            `
          default:
            return css`
              transform: translateX(-0.5em);
            `
        }
      }}
    }
  }
`

const StyledTextWrapper = styled.div`
  position: relative;
  max-width: 25em;
  height: max-content;
  text-align: ${props => (props.textAlign === "center" ? "center" : "left")};
  > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 5em;
  }
`

const TeaserImage = ({ content }) => {
  const { primary } = content
  const viewport = useViewport().size

  return (
    <StyledLink href={primary.button_link?.path}>
      <Image
        glitch
        url={primary.background_image?.url}
        style={process.env.THEME}
      >
        <StyledWrapper
          direction={primary.direction}
          image={primary.background_image?.url}
          padding={"outer-xx"}
          viewport={viewport}
        >
          <StyledTextWrapper direction={primary.direction}>
            <Heading size="h2" theme={content.theme}>
              {primary.header}
            </Heading>

            <Body size={"normal"} theme="white">
              {primary.paragraph}
            </Body>

            <Button
              type={"span"}
              theme={content.theme}
              style={process.env.THEME}
            >
              {primary.button_label}
            </Button>
          </StyledTextWrapper>
        </StyledWrapper>
      </Image>
    </StyledLink>
  )
}

export default TeaserImage
