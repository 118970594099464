import React from "react"
import DocumentsList from "./DocumentsList"
import DocumentsLegalText from "./DocumentsLegalText"

const Documents = ({ content, type }) => {
  return (
    <>
      {type === "list" && <DocumentsList content={content} />}
      {type === "legal_text" && <DocumentsLegalText content={content} />}
    </>
  )
}

export default Documents
