import React from "react"
import styled from "styled-components"
import { media } from "../../constants/breakpoints"

const StyledWrapper = styled.div`
  align-self: center;
  margin:auto;
  width:max-content;
  @media ${media.large} {
    margin:0;
  }
  
`
const StyledLabel = styled.label`
  margin-bottom: 0.5em;
  position: relative;
  display: block;
  font-size: 1em;
  font-weight:bold;
  @media ${media.large} {
    font-size: 0.8em;
  }
`
const StyledInput = styled.select`
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
  -moz-appearance: none; 
  -webkit-appearance: none; 
  appearance: none;
  position: relative;
  display: block;
  box-shadow: 0px 5px 25px -4px rgba(0, 0, 0, 0.15);
  padding: 1.5em;
  width: 22em;
  border: none;
  border-radius: 0.5em;
  margin-bottom: 2em;
  font-size: 13.33px; //because chrome has 13.33 and safari uses 11
  &::placeholder {
    opacity: 0.5;
  }
  &[data-valid="false"] {
    border: 1px solid red;
  }
  &[data-valid="true"] {
    border: 1px solid green;
  }
`

const StyleOption = styled.option`
 
`

const SelectAlterna = ({
  name,
  label,
  required,
  options,
}) => {
  return (
    <StyledWrapper>
      <StyledLabel for={name}>{label}</StyledLabel>
      <StyledInput
        name={name}
        required={required}
      >
        {options.map(option => (
          <StyleOption key={name + option} value={option}>{option}</StyleOption>
        ))}
      </StyledInput>
    </StyledWrapper>
  )
}

export default SelectAlterna
