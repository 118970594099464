import React, { useState } from "react"
import styled from "styled-components"
import { Body, Heading } from "../../typography"
import { media } from "../../../constants/breakpoints"
import { shades } from "../../../constants/colors"

const StyledWrapper = styled.div`
  grid-column-start: outer-xxx-left;
  grid-column-end: outer-xxx-right;
  @media ${media.medium} {
    grid-column-start: outer-x-left;
    grid-column-end: outer-x-right;
  }
  @media ${media.large} {
    grid-column-start: inner-left;
    grid-column-end: inner-right;
  }
`
const StyledHeader = styled.div``
const StyledCarousel = styled.div`
  display: grid;
  grid-template-columns: 10% 1fr 10%;
  width: 100%;
`
const StyledSlidesWrapper = styled.div`
  display: grid;
  height: 10em;
`
const StyledSlide = styled.div`
  grid-area: 1/1/2/2;
  align-self: center;
  opacity: ${props => (props.show ? "1" : "0")};
  transition: opacity 0.2s ease-in-out;
`
const StyledControls = styled.div`
  opacity: 0.5;
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  height: 1em;
  width: 1em;
  align-self: center;
  justify-self: center;

  transform: ${props =>
    props.direction === "right" ? "rotate(-45deg)" : "rotate(135deg)"};
`

const StyledIndicatorsWrapper = styled.div`
  display: grid;
  grid-area: 2/1/3/4;
`
const StyledIndicators = styled.div`
  display: flex;
  gap: 0.5em;
  align-self: center;
  justify-self: center;
`
const StyledIndicator = styled.div`
  width: 0.5em;
  height: 0.5em;
  border-radius: 1em;
  background-color: ${props => (props.show ? shades.gray30 : shades.gray50)};
`

const ValueStatementCarousel = ({ content }) => {
  const [slide, setSlide] = useState(0)

  const changeSlide = direction => {
    let nextSlide = direction === "next" ? slide + 1 : slide - 1

    if (nextSlide > content.fields.length - 1) {
      nextSlide = 0
    } else if (nextSlide < 0) {
      nextSlide = content.fields.length - 1
    }

    setSlide(nextSlide)
  }
  return (
    <StyledWrapper>
      <StyledHeader>
        <Heading size="h3" align="center">
          {content.primary?.header}
        </Heading>
      </StyledHeader>
      <StyledCarousel>
        <StyledControls direction="left" onClick={() => changeSlide("prev")} />
        <StyledSlidesWrapper>
          {content.fields.map(({ value_statement }, index) => (
            <StyledSlide key={index} show={slide === index}>
              <Body align="center" size="big">
                {value_statement}
              </Body>
            </StyledSlide>
          ))}
        </StyledSlidesWrapper>
        <StyledControls direction="right" onClick={() => changeSlide("next")} />
        <StyledIndicatorsWrapper>
          <StyledIndicators>
            {content.fields.map((value, index) => (
              <StyledIndicator key={index} show={slide === index} />
            ))}
          </StyledIndicators>
        </StyledIndicatorsWrapper>
      </StyledCarousel>
    </StyledWrapper>
  )
}

export default ValueStatementCarousel
