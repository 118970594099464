import React from "react"
import ValueStatementCarousel from "./ValueStatementCarousel"
import ValueStatementAutoScroll from "./ValueStatementAutoScroll"

const Values = ({ content, type }) => {
  return (
    <>
      {type === "statement_carousel" && (
        <ValueStatementCarousel content={content} />
      )}
      {type === "statement_auto_scroll" && (
        <ValueStatementAutoScroll content={content} />
      )}
    </>
  )
}

export default Values
